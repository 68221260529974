import { useState, useEffect,  } from "react";

// Found here:
// https://usehooks.com/useLocalStorage/                                                                                                                
export const useLocalStorage = (key, initialValue) => {    
    const [storedValue, setStoredValue] = useState(() => {    
        try {                
            const item = window.localStorage.getItem(key);    
            return item ? JSON.parse(item) : initialValue;    
        } catch (error) {    
            return initialValue;    
        }                    
    });                      
    const setValue = (value) => {    
        try {                
            const valueToStore =    
                value instanceof Function ? value(storedValue) : value;    
            setStoredValue(valueToStore);    
            window.localStorage.setItem(key, JSON.stringify(valueToStore));    
        } catch (error) {    
            //
        }
    };                       
    return [storedValue, setValue];    
}   

export const useObservedWindow = onClose => {
  const [childWindow, setChildWindow] = useState(null)
  const [childClosed, setChildClosed] = useState(null)

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("childClosed", childClosed, "childWindow.closed", childWindow?.closed)
      if (childWindow && childClosed !== childWindow.closed) {
        setChildClosed(childWindow.closed)
      }
    }, 250)
    return () => clearInterval(interval);
  }, [childClosed, childWindow, setChildClosed])

  useEffect(() => {
    if (childClosed === true) {
      onClose();
    }
  }, [childClosed, onClose])

  return url => {
    const win = window.open(url, "_blank")
    console.log("setting stuff here...")
    setChildWindow(win)
    setChildClosed(win.closed)
    console.log('win',win.closed, win)
  }
}