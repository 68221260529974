import 'bootstrap/dist/css/bootstrap.css';
import './CustomBootstrapTheme.css'
import './App.css';

import { I18nProvider } from '@lingui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { messages as messagesNL } from './locales/nl/messages'
import { messages as messagesEN } from './locales/en/messages'
import { messages as messagesFR } from './locales/fr/messages'

import { useLocalStorage } from './Hooks'

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom"

import { useState, useEffect } from 'react'

const DEFAULT_LANG = 'en'

i18n.load('en', messagesEN)
i18n.load('fr', messagesFR)
i18n.load('nl', messagesNL)

const hostname = window.location.hostname;

const backendUrl = (hostname === "donation.tada.brussels")
  ? "https://donation.tada.brussels/api/donate"
  : ( hostname === "localhost" || hostname === "127.0.0.1" )
  ? `http://${hostname}:8089/api/donate`
  : hostname === "10.85.1.103"
  ? `http://${hostname}/api/donate`
  : "https://donation.tada.brussels/api/donate"
  ;

const donationOptions = [
  '10.00',
  '25.00',
  '50.00',
  '250.00',
  '500.00',
  '1000.00',
  '5000.00',
  '10000.00',
]

// applepay bancontact banktransfer belfius creditcard directdebit eps giftcard giropay ideal kbc mybank paypal paysafecard przelewy24 sofort
const paymentMethods = [
  {label: <Trans>Credit card</Trans>, value: 'creditcard', icon: 'https://www.mollie.com/external/icons/payment-methods/creditcard.png'},
  {label: <Trans>Bankcontact</Trans>, value: 'bancontact', icon: 'https://www.mollie.com/external/icons/payment-methods/bancontact.png'},
  {label: <Trans>KBC/CBC Pay</Trans>, value: 'kbc', icon: 'https://www.mollie.com/external/icons/payment-methods/kbc.png'},
  {label: 'Belfius Pay', value: 'belfius', icon: 'https://www.mollie.com/external/icons/payment-methods/belfius.png'},
  {label: 'PayPal', value: 'paypal', icon: 'https://www.mollie.com/external/icons/payment-methods/paypal.png'},
  {label: <Trans>Bank transfer</Trans>, value: 'banktransfer', icon: 'https://www.mollie.com/external/icons/payment-methods/banktransfer.png'},
  {label: 'iDEAL', value: 'ideal', icon: 'https://www.mollie.com/external/icons/payment-methods/ideal.png'},
  {label: 'SOFORT Banking', value: 'sofort', icon: 'https://www.mollie.com/external/icons/payment-methods/sofort.png'},
]

const PostDonationComponent = () => {
  return (
    <>
      <div className="container">
        <div className="form-container">
          {/**
            * @TODO ask for details about this.
           */}
          Thank you for your contribution.
        </div>
      </div>
    </>
  )
}

const PaymentMethodIcon = ({ path }) => (
  <div
    style={{
      width: 32,
      height: 24,
      backgroundImage: `url("${path}")`,
      float: 'left',
      margin: '0 .5rem 0',
    }}
  />
)

const RequiredStar = () => <span style={{ color: 'red', fontWeight: 'bold' }}>*</span>
const FieldIsRequired = () => <div className="form-text"><Trans>This field is required</Trans></div>

const MakeDonationComponent = ({ lang }) => {
  const [waitingResponse, setWaitingResponse] = useState(false)
  const [form, setForm] = useState({
    amount: '50.00',
    paymentMethod: 'creditcard',
    name: '',
    email: '',
    lang,
  })

  const [showNameError, setShowNameError] = useState(false)
  const [showEmailError, setShowEmailError] = useState(false)

  const updateErrors = () => {
    if (!form.name) setShowNameError(true);
    if (!form.email) setShowEmailError(true);
    return (!form.name || !form.email)
  }

  const onSubmit = e => {
    e.preventDefault();

    const hasErrors = updateErrors();
    if (hasErrors) return;

    setWaitingResponse(true);

    const fetchData = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      // mode: 'no-cors',
      body: JSON.stringify(form),
    }

    fetch(backendUrl, fetchData)
      .then(response => response.json())
      .then(data => {
        /**
         * @NOTE we're just updating the location.href here with the mollie checkoutUrl. this is what
         * the current implementation was also doing something similar (it 302'd to checkoutUrl).
         * as an alternative we could also open a child tab here. 
         */
        const { checkoutUrl, paymentId } = data;
        window.location.href = checkoutUrl;
      })
      .catch(error => {
        setWaitingResponse(false);
      });
  }

  return (
    <>
      <div className="container vh-100">
        <div className="row">
          <div className="col-sm-12 mt-4">
            <a href="https://www.tada.brussels/">
              <img src="/img/logo.png" width="110" height="116" />
            </a>
          </div>
        </div>
        <div className="row align-items-start">
          <div className="col-lg-6"></div>
          <div className="col-lg-6 form-container">
            <div className="mb-4">
              <h1 className="fancy-font fw-bold"><Trans>Make a donation</Trans></h1>
              <small className="text-muted"><Trans>WITHOUT TAX CERTIFICATE</Trans> *</small>
            </div>

            <form>
              <div className="mb-3">
                <label htmlFor="inputName" className="form-label">{t`Your name`} <RequiredStar /></label>
                <input type="text"
                  className={`form-control ${(showNameError) ? 'is-invalid' : ''}`}
                  id="inputName"
                  value={form.name}
                  onChange={e => {
                    setForm({...form, name: e.target.value})
                    setShowNameError(false)
                  }}
                  />
                  {showNameError && <FieldIsRequired />}
              </div>

              <div className="mb-3">
                <label htmlFor="inputEmail" className="form-label">{t`E-mail address`} <RequiredStar /></label>
                <input type="email"
                  className={`form-control ${(showEmailError) ? 'is-invalid' : ''}`}
                  id="inputEmail"
                  value={form.email}
                  onChange={e => {
                    setForm({...form, email: e.target.value})
                    setShowEmailError(false)
                  }}
                  />
                  {showEmailError && <FieldIsRequired />}
              </div>

              <div className="mb-4">
                <label className="form-label">{t`Amount`} <RequiredStar /></label>
                <select className="form-select form-control" value={form.amount} onChange={e => setForm({...form, amount: e.target.value})}>
                  {donationOptions.map(o => (
                    <option key={o} value={o}>&euro; {o.replace('.', ',')}</option>
                  ))}
                </select>
              </div>

              <div className="mb-4">
                {/**
                <label className="form-label"><Trans>Payment method</Trans></label>
                */}
                {paymentMethods.map((paymentMethod, i) => (
                  <div className="mb-1" key={i}>
                    <input
                      id={`radio-option-${i}`}
                      type="radio"
                      value={paymentMethod.value}
                      checked={paymentMethod.value === form.paymentMethod}
                      onChange={e => {
                        setForm({...form, paymentMethod: e.target.value})
                      }}
                      />
                    <label htmlFor={`radio-option-${i}`}>
                      <PaymentMethodIcon path={paymentMethod.icon} />
                      {paymentMethod.label}
                    </label>
                  </div>
                ))}
              </div>

              <div className="mb-5">
                <button
                  className="btn btn-lg btn-success"
                  onClick={onSubmit}
                  disabled={waitingResponse || showNameError || showEmailError}
                  >
                  <Trans>Donate</Trans>
                </button>
              </div>

              <div className="mb-4 text-muted">
                <small>
                  <p>
                    *&nbsp;
                    <Trans>
                      Do you want a tax certificate? Then you will find all the necessary information <a href="https://www.tada.brussels/doneer-met-fiscaal-attest/">on this page</a>.
                      Since 2017 TADA has been cooperating with the "Fonds Vrienden van TADA" (managed by the King Baudouin Foundation) to provide tax certificates. If you make a donation via the form above then you will not receive a tax certificate.
                    </Trans>
                  </p>    
                  <p><Trans>Our audited financial statements are published in full transparency on</Trans></p>
                </small>
              </div>

              <div className="mb-3">
                <a href="https://donorinfo.be" target="_blank" rel="noreferrer">
                  <div className="donorinfo-link" />
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

const App = () => {
  const [lang, setLang] = useLocalStorage('lang', DEFAULT_LANG)

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString)
  const urlLang = urlParams.get("l")

  const changeLang = lang => {
    setLang(lang)
    i18n.activate(lang);
  }

  useEffect(() => {
    i18n.activate(lang)
  }, [])

  /**
   * if using deeplinked langage links then update the localStorage (if
   * it's different than the deeplink).
   */
  if (["en", "fr", "nl"].some(l => l === urlLang) && urlLang !== lang) {
    changeLang(urlLang);
  }

  return (
    <>
      <I18nProvider i18n={i18n}>
        <Router>
          <Routes>
            <Route path="/thanks" element={<PostDonationComponent lang={lang} />} />
            <Route path="/gift" element={<MakeDonationComponent lang={lang} />} />
            <Route path="*" element={<Navigate to="/gift" replace />} />
          </Routes>
        </Router>
      </I18nProvider>
    </>
  )
}

export default App;
